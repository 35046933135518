import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
  ARBITRATION_REFERENCE,
  NOTICE_TO_ARBITRATE,
  APPOINTMENT_OF_ARBITRATOR,
  ACCEPTANCE_BY_ARBITRATOR,
  FIRST_NOTICE_FIRST_HEARING_INTIMATION,
  FILING_STATEMENT_OF_CLAIM,
  FILING_OF_SECTION_SEVENTEEN,
  SECTION_SEVENTEEN_ORDER_PASSED,
  FILING_OF_STATEMENT_OF_DEFENCE,
  REJOINDER_FROM_CLAIMNT,
  SECOND_NOTICE_MOM,
  SURREJOINDER_FROM_RESPONDENT,
  CROSSEXAMINATION_CLAIMANT_WITNESS,
  CROSSEXAMINATION_RESPONDENT_WITNESS,
  ARGUMENT,
  RESERVED_FOR_AWARD,
} from "../../helpers/constants";
import CommentBox from "./common/CommentBox";
import _ from "lodash";

function MediationState({
  currentState,
  isRespondent,
  ownerId,
  caseDetails,
  showCta,
  comments,
  setComments,
  partyList,
  isLocalTranscriptionServer,
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */

  const RenderCommentBox = ({
    status,
    expand,
    errorText,
    comments,
    caseId,
    setComments,
    disabled,
    isLocalTranscriptionServer,
  }) => {
    return (
      <CommentBox
        title={`${_.capitalize(caseDetails?.resolutionKind)} Process`}
        expand={expand}
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        caseId={caseId}
        comments={comments}
        disabled={disabled}
        setComments={setComments}
        kind={"Arbitrator"}
        hasMediator={
          caseDetails?.requestedMediators?.length &&
          caseDetails?.requestedMediators?.some((el) => el?.id)
        }
        hideAddComment={false}
        partyList={partyList}
        isLocalTranscriptionServer={isLocalTranscriptionServer}
      />
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
            // disabled={true}
          />
        </div>
      );
    case AWAITING_RESPONDANT_QUIT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by IIAC Counsel"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Claimant"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Respondent"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by IIAC Counsel"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_REACHED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case ARBITRATION_REFERENCE:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case NOTICE_TO_ARBITRATE:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case APPOINTMENT_OF_ARBITRATOR:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case ACCEPTANCE_BY_ARBITRATOR:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case FIRST_NOTICE_FIRST_HEARING_INTIMATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case FILING_STATEMENT_OF_CLAIM:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case FILING_OF_SECTION_SEVENTEEN:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case SECTION_SEVENTEEN_ORDER_PASSED:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case FILING_OF_STATEMENT_OF_DEFENCE:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case REJOINDER_FROM_CLAIMNT:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case SURREJOINDER_FROM_RESPONDENT:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case SECOND_NOTICE_MOM:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case CROSSEXAMINATION_CLAIMANT_WITNESS:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case CROSSEXAMINATION_RESPONDENT_WITNESS:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case ARGUMENT:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case RESERVED_FOR_AWARD:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "finalAward":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    default:
      return null;
  }
}

export default React.memo(MediationState);
