import { Modal } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const CounterClaimModal = ({
  claimModal,
  setClaimModal,
  MTRef,
  caseDetails,
}) => {
  return (
    <Modal
      open={claimModal}
      onClose={() => setClaimModal(false)}
      disableRestoreFocus
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Counter Claim"}</Heading>
          <CloseModal
            onClick={() => setClaimModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <FieldWrapper>
            <BasicDetailsLabel>Counter Claim Vlaue</BasicDetailsLabel>
            <BasicDetailsValue>
              {Math.round(caseDetails?.counterClaimValue) > 0 ? caseDetails?.counterClaimValue : "-"}
            </BasicDetailsValue>
          </FieldWrapper>
          <FieldWrapper>
            <BasicDetailsLabel>Counter Claim Description</BasicDetailsLabel>
            <BasicDetailsValue>
              {caseDetails.counterClaimDescription
                ? caseDetails?.counterClaimDescription
                : "-"}
            </BasicDetailsValue>
          </FieldWrapper>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default CounterClaimModal;

const DrawerContainer = styled.div`
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 586px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 0px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 14px;
  height: 195px;
  overflow: auto;
`;

const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

const BasicDetailsValue = styled.div`
  word-break: break-all;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
`;

const FieldWrapper = styled.div`
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }
  padding-right: 30px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 5px;
    }
    margin-top: 5px;
    width: auto;
    padding-right: 0;
    margin-right: 20px;
  }
`;
