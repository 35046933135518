import React, { useState, useEffect, useRef } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import StatusBadge from "../../common/StatusBadge";
import { Images } from "../../../assets/images";
import { DropDown, Icon, Actions, Heading, Header } from "../styles";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import _ from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { PrimaryCTAButton } from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, parseTimeStamp } from "../../../helpers/functions";
import useUser from "../../../hooks/useUser";
import InfiniteScroll from "react-infinite-scroller";
import { sanitize } from "dompurify";

var icons = ReactQuill.Quill.import("ui/icons");
icons[
  "bold"
] = `<img src=${require("../../../assets/images/editor/bold.svg")} aria-hidden="true" />`;
icons[
  "italic"
] = `<img src=${require("../../../assets/images/editor/italics.svg")} aria-hidden="true" />`;
icons[
  "underline"
] = `<img src=${require("../../../assets/images/editor/underline.svg")} aria-hidden="true" />`;
icons[
  "blockquote"
] = `<img src=${require("../../../assets/images/editor/blockQuote.svg")} aria-hidden="true" />`;
icons["align"][
  ""
] = `<img src=${require("../../../assets/images/editor/leftAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "center"
] = `<img src=${require("../../../assets/images/editor/centerAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "right"
] = `<img src=${require("../../../assets/images/editor/rightAlign.svg")} aria-hidden="true" />`;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`,
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0px !important`,
  },
  paper: {
    boxShadow: "none",
    border: "solid 0.5px #e1e3ee",
  },
  menuRoot: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    fontSize: 12,
    color: COLORS.COLOR_DARK,
  },
}));

const roleColors = {
  mediator: {
    color: "#293461",
    "background-color": "rgba(246, 180, 54, 0.3)",
  },
  caseManager: {
    color: "#f4f6ff",
    "background-color": "#293461",
  },
  respondent: {
    color: "#293461",
    "background-color": "rgba(255, 60, 60, 0.3)",
  },
  claimant: {
    color: "#293461",
    "background-color": "rgba(0, 131, 140, 0.3)",
  },
  other: {
    color: "#293461",
    "background-color": "rgba(0, 131, 140, 0.3)",
  },
};

export default function CommentBox({
  title,
  status,
  errorText,
  comments,
  caseId,
  hideAddComment = true,
  partyList = [],
  kind = "Mediator",
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    comments?.length > 0 || hideAddComment,
  );
  const [trigggerStateChange, setTrigggerStateChange] = useState(false);
  const chatContainerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject } = useUser();

  useEffect(() => {
    if (trigggerStateChange) {
      setTrigggerStateChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigggerStateChange]);

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatContainerRef]);

  const loadMore = () => {
    console.log("load more");
  };

  async function downloadComments(caseId) {
    try {
      setLoader({ state: true, message: "Download comments" });
      const response = await CaseService.downloadComments(caseId);
      if (response) {
        enqueueSnackbar("Download completed", {
          variant: "success",
        });
        window.open(response?.fileUrl);
      }
      setExpanded(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }
  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          classes={{ content: classes.content }}
          onClick={() => setExpanded((e) => !e)}
        >
          <Header>
            <div className="title-container">
              <Heading
                style={{
                  marginBottom: "unset",
                  marginLeft: "21px",
                  textTransform: "none",
                  fontWeight: "800",
                  fontSize: "18px",
                }}
              >
                {title}
                {comments?.length ? (
                  <PrimaryCTAButton
                    style={{
                      padding: "4px 10px",
                      width: "70px",
                      marginLeft: "20px",
                      borderRadius: "5px",
                    }}
                    onClick={() => downloadComments(caseId)}
                  >
                    Export
                  </PrimaryCTAButton>
                ) : null}
              </Heading>
              {status && (
                <StatusBadge
                  text={
                    status === "success"
                      ? "Negotiation Successful"
                      : errorText
                      ? errorText
                      : "Negotiation In Progress"
                  }
                  status={status}
                />
              )}
            </div>
            <Actions>
              <DropDown>
                <Icon
                  src={Images.dropDownArrow}
                  alt="dropdown"
                  expanded={expanded}
                />
              </DropDown>
            </Actions>
          </Header>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {comments?.length > 0 || hideAddComment ? (
            <div>
              <div
                ref={chatContainerRef}
                style={{
                  padding: `0 20px 20px`,
                  maxHeight: 400,
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadMore}
                  initialLoad={false}
                  hasMore={false}
                  useWindow={false}
                  threshold={50}
                  isReverse={true}
                  loader={
                    <Loader key={0}>
                      <CircularProgress />
                    </Loader>
                  }
                >
                  {" "}
                  {comments
                    ?.sort(
                      (a, b) =>
                        new Date(moment(a?.created_at)) -
                        new Date(moment(b?.created_at)),
                    )
                    ?.map((comment) => (
                      <CommentContainer
                        isOwner={
                          comment?.ownerId === userObject?.id &&
                          comment?.ownerType === "caseManager"
                        }
                      >
                        <div id="flex">
                          <div className="flex">
                            <CommentUserName>
                              {comment?.owner?.name}
                            </CommentUserName>
                            <SenderRole
                              style={{ marginLeft: 15 }}
                              role={
                                roleColors[
                                  comment?.ownerType === "agent"
                                    ? comment?.ownerRole
                                    : comment?.ownerType
                                ]
                              }
                            >
                              {_.startCase(
                                comment?.ownerType === "agent"
                                  ? comment?.ownerRole
                                  : comment?.ownerType === "mediator"
                                  ? kind
                                  : comment?.ownerType,
                              )}
                            </SenderRole>
                          </div>
                        </div>
                        <CommentTimestamp>
                          {moment(parseTimeStamp(comment?.created_at)).format(
                            "MMMM D, YYYY",
                          ) +
                            " at " +
                            moment(parseTimeStamp(comment?.created_at)).format(
                              "hh:mm A",
                            )}
                        </CommentTimestamp>
                        {comment?.content && (
                          <CommentContent
                            dangerouslySetInnerHTML={{
                              __html: sanitize(comment?.content),
                            }}
                          />
                        )}
                        {!_.isEmpty(comment?.zoomLink) && (
                          <div>
                            <div className="data-container">
                              <div className="data-field">
                                <div className="label">Date</div>
                                <div className="value">
                                  {moment(
                                    new Date(comment?.zoomLink?.date),
                                  ).format("DD-MM-YYYY")}
                                </div>
                              </div>
                              <div
                                className="data-field"
                                style={{ marginLeft: 30 }}
                              >
                                <div className="label">Time</div>
                                <div className="value">
                                  {moment(
                                    new Date(comment?.zoomLink?.date),
                                  ).format("hh:mm A")}{" "}
                                  (IST)
                                </div>
                              </div>
                            </div>
                            <div className="action_container">
                              <div className="action-btn">
                                <PrimaryCTAButton
                                  disabled={
                                    moment(new Date()).isAfter(
                                      moment(
                                        new Date(comment?.zoomLink?.date),
                                      ).add(1, "day"),
                                    ) || comment?.zoomLink?.disable
                                  }
                                  onClick={() =>
                                    window.open(
                                      comment?.zoomLink?.url,
                                      "_blank",
                                    )
                                  }
                                >
                                  Join
                                </PrimaryCTAButton>
                              </div>
                            </div>
                          </div>
                        )}
                      </CommentContainer>
                    ))}
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            <EmptyMsg>There are no comments yet.</EmptyMsg>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

const CommentContainer = styled.div`
  padding: 18px;
  border-radius: 7px;
  background-color: ${(props) =>
    props.isOwner ? COLORS.LIGHT_BLUE : COLORS.PRIMARY_WHITE};
  border: solid 1px
    ${(props) => (props.isOwner ? COLORS.COLOR_DARK : COLORS.INPUT_BORDER)};
  margin-bottom: 12px;
  max-width: 730px;
  & #flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .data-container {
    display: flex;
    padding: 15px 0px;
    & .data-field {
      font-family: ${theme.fonts.primaryFontSemiBold};
      & .label {
        color: ${COLORS.INPUT_LABEL};
        font-size: 10px;
      }
      & .value {
        color: ${COLORS.PRIMARY_BLACK};
        margin-top: 2px;
      }
    }
  }

  & .action_container {
    display: flex;
    padding-top: 15px;
    .action-btn {
      width: 150px;
    }
  }
`;

const CommentUserName = styled.div`
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
`;

const CommentTimestamp = styled.div`
  color: #acb1c2;
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 8px;
`;

const CommentContent = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 18px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-right {
    text-align: right;
  }
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 16px;
    background-color: #f9f9f9;
  }
`;

const SenderRole = styled.div`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  padding: 4px 10px;
  border-radius: 4px;
  ${({ role }) =>
    role
      ? Object.keys(role).map(
          (key) =>
            css`
              ${key}: ${role[key]};
            `,
        )
      : null}
  user-select:none;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
`;

const EmptyMsg = styled.div`
  color: ${COLORS.COLOR_DARK};
  font-weight: 500;
  letter-spacing: 0.1px;
  font-size: 24px;
  margin: 20px 0px;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px 0px;
  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }
`;

export const ButtonCard = styled.div`
  // display: flex;
  align-items: center;
  padding: 0 12px 5px;
`;

export const ResButtonCard = styled.div`
  // display: flex;
  align-items: center;
  padding: 0 16px 5px;
`;
