import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { Modal } from "@material-ui/core";
import AlertDialog from "../../common/Alert";
import { CheckboxContainer, CustomCheckbox } from "../../common/FormInputs";

export default function CaseLifeCycleStatus({
  lifeCycle,
  setLifeCycle,
  status,
  setStatus,
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (lifeCycle) {
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCycle]);

  async function addStatus(selectedStatus) {
    try {
      setLoader({ state: true, message: "Adding Status..." });

      const response = selectedStatus;
      if (response) {
        setStatus(response);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      setOpen(false);
    } finally {
      setLoader({ state: false });
      setLifeCycle(false);
      setOpen(false);
    }
  }

  const actions = [
    {
      label: "Notice To Arbitrate",
      value: "noticeToArbitrate",
    },
    {
      label: "Appointment of Arbitrator",
      value: "appointmentOfArbitrator",
    },
    {
      label: "First Hearing Intimation",
      value: "firstHearingIntimation",
    },
    {
      label: "Framing of Issues",
      value: "framingOfIssues",
    },
    {
      label: "Pleadings",
      value: "pleadings",
    },
    {
      label: "Cross Examination - Claimant Witness",
      value: "crossExaminationClaimantWitness",
    },
    {
      label: "Cross Examination - Respondent Witness",
      value: "crossExaminationRespondentWitness",
    },
    {
      label: "Arguments",
      value: "arguments",
    },
    {
      label: "Reserved for Award",
      value: "reservedForAward",
    },
  ];

  return (
    <Modal
      open={lifeCycle}
      onClose={() => setLifeCycle(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Case Status"}</Heading>
          <CloseModal
            onClick={() => setLifeCycle(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <div className="ml40 mr40">
          <div
            className="mt20 mb20 h300"
            style={{
              overflow: "auto",
            }}
          >
            <>
              {actions?.length > 0 ? (
                <>
                  {actions?.map((action, index) => (
                    <CheckboxContainer className="status_row">
                      <CustomCheckbox
                        key={index}
                        checked={action.value === status.value}
                        onChange={() => {
                          setOpen(true);
                          setStatus({
                            label: action.label,
                            value: action.value,
                          });
                        }}
                      />
                      <div className="status_name">{action?.label}</div>
                    </CheckboxContainer>
                    // <div
                    //   className={`neutral_row ${
                    //     action?.disabled ? "disabled" : ""
                    //   }`}
                    // >
                    //   <div className="status_name">
                    //     <div>{action?.label}</div>
                    //   </div>
                    //   <div
                    //     className="add_circle"
                    //     onClick={() => {
                    //       if (!action?.disabled) {
                    //         setOpen(true);
                    //         setStatus({
                    //           label: action.label,
                    //           value: action.value,
                    //         });
                    //       }
                    //     }}
                    //   >
                    //     <AddIcon className="add_icon" />
                    //   </div>
                    // </div>
                  ))}
                </>
              ) : (
                <div className="no_result">No Case Lifecycle found.</div>
              )}
            </>
          </div>
          <div>
            <AlertDialog
              isOpen={open}
              clickSecondarybtn={() => setOpen(false)}
              onDialogClose={() => setOpen(false)}
              primaryBtnText={"Ok"}
              secondaryBtnText={"Cancel"}
              desc={
                <StyledTextSemi>
                  Are you sure you want to update status to{" "}
                  <StyledText>{status.label}</StyledText>
                </StyledTextSemi>
              }
              heading={"Update Case Status"}
              clickPrimaryBtn={() => addStatus(status)}
              descriptionTextStyle={{
                textAlign: "left",
                fontFamily: theme.fonts.primaryFontSemiBold,
              }}
            />
          </div>
        </div>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no_result {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  & .status_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    }
    & .status_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
