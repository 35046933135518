import React, { useState, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import { navigate } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import FilterModal from "./modal";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import { useEffect } from "react";
import {
  numberFormat,
  getCaseLabelByStatus,
  getErrorMessage,
  parseTimeStamp,
} from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import AlertDialog from "../../common/Alert";
import { PrimaryCTAButton } from "../../common/Buttons";
import usePrevious from "../../../hooks/usePrevious";
import AssignNeutralBulkCase from "../../pages/AssignNeutralBulkCase/index";
import AssignCaseLifeCycleBulkCase from "../../pages/AssignCaseLifeCycleBulkCase/index";

const initFilters = [
  { label: "All", key: "allCount", value: 0 },
  {
    label: "Waiting For Respondent",
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  { label: "Under Resolution", key: "underResolution", value: 0 },
  { label: "Settled", key: "settled", value: 0 },
  { label: "Drafts", key: "draftCount", value: 0 },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Drafts":
      value = "draft";
      break;
    case "Under Resolution":
      value = "underResolution";
      break;
    case "Waiting For Respondent":
      value = "awaitingRespondent";
      break;
    case "Settled":
      value = "settled";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCases(query = "", type) {
  try {
    const param = `?resolutionKind=${type}`;
    const response = await CaseService.index(query);
    const stats = await CaseService.caseStats(param);
    return { ...response, stats };
  } catch (error) {
    return error;
  }
}

const Index = ({ type }) => {
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const placeholderText = "Search Cases";
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const [modal, setModal] = useState();
  const filterPreviousValue = usePrevious(filters);
  const MTRef = useRef();
  const breadcrumbs = [_.startCase(labels.cases), _.startCase(type)];
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [lifecycleModal, setLifeCycleModal] = useState({ state: false });

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (selectedFilter && filterPreviousValue) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  const actions = [
    {
      text: "Assign Neutral",
      props: {
        onClick: () => setDrawer({ state: true }),
      },
      hidden: type !== "negotiation" ? false : true,
    },
    {
      text: "Case Status",
      props: {
        onClick: () => setLifeCycleModal({ state: true }),
      },
      hidden: type === "arbitration" ? false : true,
    },
  ];

  const columns = [
    {
      field: "id",
      title: "Case ID",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
          }
        >
          {rowData.id}
        </HyperLink>
      ),
      tooltip: "Unique Identifier for the Case across IIAC platform",
      headerStyle: {
        paddingLeft: "26px",
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
          }
        >
          {rowData.title}
        </Bold>
      ),
      headerStyle: {
        width: "18%",
      },
      sorting: false,
    },
    {
      field: "party",
      title: "Party",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
          }
        >
          {rowData?.claimantParty?.name}
        </div>
      ),
      tooltip: "Parties representing to file the case",
      sorting: false,
    },
    {
      field: "respondentName",
      title: "Counterparty",
      tooltip: "Party countering your case",
      sorting: false,
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
          }
        >
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </div>
      ),
    },
    {
      field: "totalClaimValue",
      title: "Claim Value",
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const caseItems = rowData.totalClaimValue;
        const divStyle = {
          cursor: "pointer",
        };
        if (caseItems)
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
              }
            >
              {numberFormat(
                parseFloat(caseItems).toFixed(2),
                rowData?.currencyUnit
              )}
            </div>
          );
        else
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
              }
            >
              {"Non Monetary"}
            </div>
          );
      },
      sorting: true,
      headerStyle: {
        paddingLeft: "8px",
      },
    },
    {
      field: "nextHearingDate",
      title: labels["table.nextHearingDate"],
      render: (rowData) => (
        <div
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={() =>
            rowData?.resolutionKind !== "negotiation"
              ? getMeetingjoin(rowData.id)
              : null
          }
        >
          {rowData?.nextHearingDate?.date
            ? rowData?.nextHearingDate?.date
            : "-"}
        </div>
      ),

      sorting: true,
      headerStyle: {
        width: 110,
      },
    },
    {
      field: "status",
      title: "Status",
      tooltip: "Status of the Case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
          }
        >
          {getCaseLabelByStatus(rowData)}
        </div>
      ),
    },
    {
      field: "submittedOn",
      title: "Created",
      tooltip: "Case Created Date",
      headerStyle: {
        width: 120,
      },
      render: (rowData) => (
        <Datecolumn>
          <div
            className="date"
            onClick={() =>
              navigate(`/dashboard/${rowData?.resolutionKind}/${rowData.id}`)
            }
          >
            {rowData?.submittedOn
              ? moment(rowData.submittedOn).format("DD/MM/YYYY")
              : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          {rowData?.status === "draft" && (
            <div
              className="delete"
              onClick={() => deleteWaring(false, rowData?.id)}
            >
              {labels["table.delete"]}
            </div>
          )}
        </Datecolumn>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (type) {
        params.resolutionKind = type;
      }
      params.selected = true;
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams, type)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result?.data?.map((row) => ({
                ...row,
                tableData: { checked: row?.isSelected },
              })),
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleCaseSelection = async (cases, data) => {
    const param = `?resolutionKind=${type}`;
    if (cases?.length >= 1 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.addAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else if (cases?.length === 0 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.removeAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        if (data?.isSelected) {
          await CaseService.removeCaseItem(data.id);
        } else {
          await CaseService.selectCaseItem(data?.id);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    }
  };

  const deleteWaring = (isSelectAll, id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () =>
        isSelectAll ? deleteSelectedDraft() : deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case(s)?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        margin: "0px 60px 50px",
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  /**
   * @description Function to deleted the selected case
   */
  const deleteSelectedDraft = async () => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteSelectedCases();
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  return (
    <div className="myCases">
      <ActionBar breadcrumbs={breadcrumbs} actions={actions} />
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle="Cases"
          singularTitle=""
          selection={selectedFilter === "Drafts"}
          onSelectionChange={handleCaseSelection}
          onFilterClick={() => setModal(true)}
          {...{
            columns,
            data,
            filters,
            selectedFilter,
            setSelectedFilter,
            placeholderText,
            MTRef,
          }}
          state={state}
        />
        <Footer>
          <div className="flex mt20">
            <div
              style={{
                display:
                  selectedFilter === "Drafts" &&
                  state?.data?.some((el) => el.isSelected)
                    ? "flex"
                    : "none",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <DangerBtn
                style={{
                  width: "216px",
                  marginLeft: "26px",
                }}
                onClick={() => deleteWaring(true)}
              >
                {labels["table.delete"]}
              </DangerBtn>
            </div>
          </div>
        </Footer>
        <FilterModal {...{ modal, setModal }} />
        <AssignNeutralBulkCase {...{ drawer, setDrawer }} />
        <AssignCaseLifeCycleBulkCase
          {...{ lifecycleModal, setLifeCycleModal }}
        />
        <AlertDialog isOpen={open} {...{ ...dialogData }} />
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;
const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Datecolumn = styled.div`
  .date {
    cursor: pointer;
  }
  .delete {
    margin-top: 2px;
    cursor: pointer;
    color: ${COLORS.LOGOUT_RED};
    font-size: 12px;
  }
`;

export const Footer = styled.footer`
  & .highlighted {
    color: red;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

export const DangerBtn = styled(PrimaryCTAButton)`
  background-color: #ff3c3c;
  &:focus,
  &:hover {
    background-color: #ff4d4d;
    border: 1px solid #ff3c3c;
  }
  border: 1px solid #ff3c3c;
`;
