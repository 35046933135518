import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { Formik, FieldArray } from "formik";
import { ConfirmArbitratorValidationSchema } from "../../../../helpers/validationSchema";
import { getFileName } from "../../../../helpers/functions";
import { Modal } from "@material-ui/core";
import { CustomFileUpload } from "../../../common/FormInputs";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import FormField from "../../../common/FormField/FormField";
import { StyledLabel } from "../../../pages/CaseDetails/styles";
import { dummyFileUpload } from "./function";
import { getErrorMessage } from "../../../../helpers/functions";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CaseService from "../../../../services/CaseService";
import AWSService from "../../../../services/AWSService";

export default function ConfirmArbitrator({
  modal,
  setModal,
  setTriggerNotify,
}) {
  // let caseObj = {};
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Confirm arbitrator..." });
      const payload = {
        url: values.url[0],
        visibilityType: "all",
        confirmArbitrator: true,
      };
      const response = await CaseService.caseAddDocument(payload, modal.id);
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTriggerNotify(true);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  }

  return (
    <>
      <Modal open={modal.state} onClose={() => setModal({ state: false })}>
        <DrawerContainer role="presentation">
          <HeadingContainer>
            <Heading>{"Arbitrator Confirmation"}</Heading>
            <CloseModal
              onClick={() => setModal({ state: false })}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                // url:
                //   caseObj?.url && caseObj.url.length
                //     ? setInitialAgreementUrl(caseObj.url)
                //     : [dummyFileUpload],
                url: [dummyFileUpload]
              }}
              enableReinitialize
              validateOnBlur
              validationSchema={ConfirmArbitratorValidationSchema}
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
              }) => (
                <>
                  <StyledFormElementsWrapper
                    style={{
                      marginBottom: 3,
                    }}
                  >
                    <FieldArray
                      name="url"
                      render={({ push }) => (
                        <>
                          {values?.url?.map((url, index) => (
                            <>
                              <Row
                                style={{
                                  justifyContent: "space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <StyledLabel>
                                  Arbitrator Confirmation Related Documents{" "}
                                </StyledLabel>
                              </Row>
                              <div style={{ marginBottom: 13 }}>
                                <div style={{ marginRight: 10, width: "100%" }}>
                                  <FormField
                                    white
                                    input={{
                                      type: "select",
                                      name: `uploadType`,
                                    }}
                                    menuItems={[{ label: "Arbitrator Confirmation Document", value: "Arbitrator Confirmation Document" }]}
                                    values={values?.url[index]}
                                    touched={
                                      touched?.url?.length &&
                                        touched?.url[index]?.uploadType
                                        ? touched?.url[index]
                                        : {}
                                    }
                                    errors={
                                      errors?.url?.length &&
                                        errors?.url[index]?.uploadType
                                        ? errors?.url[index]
                                        : {}
                                    }
                                    handleChange={(e) => {
                                      setFieldValue(
                                        `url[${index}].uploadType`,
                                        e?.target?.value
                                      );
                                    }}
                                    disabled={true}
                                    {...{
                                      handleBlur,
                                      setFieldValue,
                                    }}
                                  ></FormField>
                                </div>
                                {/* File upload extra field */}
                                <CustomFileUpload
                                  error={
                                    touched?.url?.length &&
                                    touched?.url[index]?.url &&
                                    errors?.url?.length &&
                                    errors?.url[index]?.url
                                  }
                                  setFile={(file) =>
                                    s3Upload(
                                      file,
                                      `url[${index}].url`,
                                      setFieldValue,
                                      setFieldError
                                    )
                                  }
                                  style={{
                                    marginTop: 10,
                                    width: "100%",
                                  }}
                                  accept=".pdf, .docx, .txt, .xlsx"
                                  file={{ name: getFileName(url?.url) }}
                                  fileUrl={url?.url}
                                />
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    />
                  </StyledFormElementsWrapper>
                  <Flex>
                    <HalfWidth>
                      <PrimaryOutlinedCTAButton
                        onClick={() => setModal({ state: false })}
                      >
                        {"Cancel"}
                      </PrimaryOutlinedCTAButton>
                    </HalfWidth>

                    <HalfWidth>
                      <PrimaryCTAButton onClick={handleSubmit}>
                        {"Confirm Arbitrator"}
                      </PrimaryCTAButton>
                    </HalfWidth>
                  </Flex>
                </>
              )}
            </Formik>
          </FormContainer>
        </DrawerContainer>
      </Modal>
    </>
  );
}

const DrawerContainer = styled.div`
  max-width: 95%;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  padding-top: 14px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 436px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
