import React from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import moment from "moment";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import { AddHearingDateValidation } from "../../../helpers/validationSchema";

export default function HearingDateModal({
  openHearing,
  setOpenHearing,
  caseDetails,
  setRefreshDetails,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  let id = caseDetails?.id;

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: `Uploading NextHearing Date` });
      const date = moment(new Date(values.startDate)).format("YYYY-MM-DD");
      const time = moment(new Date(values?.startTime)).format("HH:mm:ss");
      const selectedTime = moment(`${date} ${time}`);
      const postData = {
        nextHearingDate: {
          date: values.startDate
            ? moment(selectedTime).format("DD/MM/YYYY HH:mm:ss")
            : null,
          time: values?.startTime
            ? moment(selectedTime).format("DD/MM/YYYY HH:mm:ss")
            : null,
          text: values?.text,
        },
      };
      const res = await CaseService.nextHearingDate(postData, id);
      if (res) {
        enqueueSnackbar("Hearing Date Updated Successfully", {
          variant: "success",
        });
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenHearing(false);
    }
  }

  return (
    <Modal
      open={openHearing}
      onClose={() => setOpenHearing(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Hearing Date</Heading>
            <CloseModal
              onClick={() => setOpenHearing(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                // startDate: openHearing?.id
                //   ? new Date(caseDetails.nextHearingDate?.date)
                //   : new Date(),
                startDate: openHearing?.id ? null : null,
                startTime: openHearing?.id
                  ? new Date(caseDetails.nextHearingDate?.date)
                  : null,
                text: openHearing?.id ? openHearing?.reason : "",
              }}
              validationSchema={AddHearingDateValidation}
              onSubmit={onFormSubmit}
              validateOnBlur
              validateOnChange
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <div className="date-time">
                    <div className="date">
                      <FormField
                        input={{
                          name: "startDate",
                          label: "Date",
                          type: "date",
                          // required: true
                        }}
                        placeholderText="N/A"
                        minDate={new Date()}
                        {...{
                          touched,
                          errors,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        }}
                      />
                    </div>
                    <div className="time">
                      {/* <FormField
                        input={{
                          name: "startTime",
                          label: "Time (IST)",
                          type: "time",
                          // required: true
                        }}
                        placeholderText="N/A"
                        {...{
                          touched,
                          errors,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue
                        }}
                      /> */}
                      {values.startDate ? (
                        <FormField
                          input={{
                            name: "startTime",
                            label: "Time (IST)",
                            type: "time",
                            required: true,
                          }}
                          placeholderText="N/A"
                          {...{
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      ) : (
                        <FormField
                          input={{
                            name: "startTime",
                            label: "Time (IST)",
                            type: "time",
                            // required: true
                          }}
                          placeholderText="N/A"
                          {...{
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="reason">
                    <FormField
                      input={{
                        name: "text",
                        label: "Reason",
                        type: "text",
                        required: true,
                      }}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  </div>
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{ width: "45%" }}
                      onClick={() => setOpenHearing(false)}
                    >
                      Cancel
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "45%" }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </StyledForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

// const Label = styled.div`
//   font-family: ${theme.fonts.primaryFontRegular};
//   font-size: 10px;
//   line-height: 1.7;
//   text-transform: uppercase;
//   color: ${COLORS.COLOR_DARK};
// `;

// const AgentFormContainer = styled.p`
//   padding: 24px 15px 30px 15px;
//   color: ${COLORS.COLOR_DARK};
//   font-size: 14px;
//   font-family: ${theme.fonts.primaryFontRegular};
//   line-height: 1.71;
//   text-align: justify;
//   min-height: 200px;
//   @media ${theme?.breakpoints?.sm_up} {
//     padding-top: 24px;
//     padding-left: 40px;
//     padding-right: 40px;
//     padding-bottom: 30px;
//   }
// `;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin: 12px;
      width: 100%;
    }
    .time {
      margin: 12px;
      width: 100%;
    }
  }
  .reason {
    margin: 12px;
  }
`;
