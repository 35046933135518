import React, { useState } from "react";
import ActionBar from "../../common/ActionBar";
import { Header } from "../Header/Header";
import labels from "../../../helpers/labels.json";
import AWSService from "../../../services/AWSService";
import styled from "styled-components";
import { CustomFileUpload, CustomInputField } from "../../common/FormInputs";
import { getFileName } from "../../../helpers/functions";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import _ from "lodash";
import { navigate } from "@reach/router";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import { bulk_notice_template } from "../../../helpers/constants";

const Index = () => {
  const [url, setUrl] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumbs = [_.startCase(labels.my_tasks), "Bulk Notice"];
  const [docx, setDocx] = useState("");
  const [value, setValue] = useState("");
  const [mailId, setMailId] = useState();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_tasks:
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  async function onFormSubmit(url, docx, value) {
    try {
      setLoader({
        state: true,
        message: `Update Bulknotice...`,
      });
      const paylod = {
        excelFile: url,
        noticeTemplate: docx,
        templateName: value,
        mailId: mailId,
      };
      const response = await CaseService.bulkNoticeGenerate(paylod);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      if (response?.fileUrl) {
        window.open(response.fileUrl);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setUrl("");
      setDocx("");
      setValue("");
      setMailId("");
    }
  }

  const actions = [
    {
      label: "Upload xl template",
      accept: ".xlsx",
      file: url,
    },
    {
      label: "Upload notice template",
      accept: ".docx",
      file: docx,
    },
  ];

  async function s3Upload(file, accept) {
    if (file) {
      try {
        let types = [];
        if (accept === ".xlsx") {
          types = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
          ];
        } else {
          types = [
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "docx",
          ];
        }
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const errorMessage = "Invalid File Type";
        if (!types.includes(file?.type)) throw errorMessage;
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          if (accept === ".xlsx") {
            setUrl(res);
          } else setDocx(res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Header
      showSidebar
      selectedItem={labels.my_tasks}
      selectedSubItem={labels.bulk_notice}
    >
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <ContainerBox>
          <Container>
            {actions.map((file) => (
              <DivContainer>
                <FormLabel>{file.label}</FormLabel>
                <CustomFileUpload
                  error={false}
                  setFile={(data) => s3Upload(data, file.accept)}
                  file={{ name: getFileName(file.file) }}
                  accept={file.accept}
                  style={{
                    marginTop: "10px",
                    marginBottom: "30px",
                    width: "98%",
                  }}
                />
              </DivContainer>
            ))}
            <FormLabel>{"Template Name"}</FormLabel>
            <CustomSelect
              id="templateName"
              name={"templateName"}
              menuItemValues={bulk_notice_template}
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                width: "98%",
              }}
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
            <FormLabel>{"Mail ID (Optional)"}</FormLabel>
            <CustomInputField
              variant="outlined"
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                width: "98%",
              }}
              value={mailId}
              onChange={(e) => setMailId(e.target.value)}
            />
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{
                  width: "45%",
                }}
                onClick={() => navigate(`/dashboard`)}
              >
                {"Back"}
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                disabled={!url || !docx || !value}
                style={{
                  width: "45%",
                }}
                onClick={() => onFormSubmit(url, docx, value)}
              >
                {"Submit"}
              </PrimaryCTAButton>
            </ButtonContainer>
          </Container>
        </ContainerBox>
      </Container>
    </Header>
  );
};

export default Index;

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

const ContainerBox = styled.div`
  width: 40%;
  position: absolute;
  background-color: white;
  outline: 2px solid ${COLORS.BORDER_GREY};
  border-radius: 8px;
`;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  background-color: white;
  outline: 2px solid ${COLORS.BORDER_GREY};
  border-radius: 8px;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const DivContainer = styled.div``;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  text-transform: capitalize;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;
