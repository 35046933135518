import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const SignupContainer = styled.div`
  background: ${COLORS.PRIMARY_WHITE};
  border-radius: 11px;
  width: 100%;
  margin: 40px 15px;
  padding: 50px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${theme?.breakpoints?.sm_up} {
    width: 500px;
    margin: 20px 0px;
    padding: 30px 50px;
  }
`;
