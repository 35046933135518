import React from "react";
import { Select } from "@material-ui/core";
import { StyledSelectFormControl, StyledDropdownIcon } from "./styles";
import { StyledMenuItem, CustomCheckBox } from "../FormInputs";
import styled from "styled-components";
import _ from "lodash";
import COLORS from "../../../assets/Colors";
import { Tooltip } from "@material-ui/core";

const CustomSelect = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  menuItemValues,
  multiple = false,
  selectedItems = [],
  white = false,
  helperText,
  showId = false,
  color,
  ...props
}) => {
  function renderValue(selectValue) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue) => selectValue?.includes(menuItemValue?.value))
        .map((menuItemValues) => menuItemValues?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue) => menuItemValue.value === selectValue
      );
      return _.trim(selectValue) === "" ? (
        <PlaceHolderText>{selectedKey?.label}</PlaceHolderText>
      ) : value === "Arbitration Notice" ? (
        <Tooltip title={value} placement="top">
          <b style={{ color: COLORS.BOX_OLIVE, fontSize: "14px" }}>{value}</b>
        </Tooltip>
      ) : (
        <BoldText {...{ selectedKey }}>{selectedKey?.label}</BoldText>
      );
      // <b style={{ color: selectedKey.color }}>{selectedKey?.label}</b>
    }
  }

  return (
    <StyledSelectFormControl
      className={white ? "input-white" : ""}
      variant="outlined"
    >
      <Select
        {...{ multiple, name, value, onChange, renderValue, disabled, color }}
        {...props}
        IconComponent={() => (
          <StyledDropdownIcon
            alt="select"
            src={require("../../../assets/images/dropdownIcon.svg")}
          />
        )}
      >
        {menuItemValues.map((item, idx) => (
          <StyledMenuItem
            style={{ color: item.color }}
            key={idx}
            value={item.value}
            disabled={item.disabled}
          >
            {multiple && (
              <CustomCheckBox checked={selectedItems.includes(item.value)} />
            )}
            {item.label} {showId && `(Party Id: ${item.value})`}
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && <ErrorLabel>{helperText}</ErrorLabel>}
    </StyledSelectFormControl>
  );
};

export default CustomSelect;

const PlaceHolderText = styled.div`
  opacity: 0.5;
`;

const ErrorLabel = styled.div`
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
  margin: 5px 0px 0px 5px;
`;

const BoldText = styled.b`
  font-size: 16px;
  color: ${({ selectedKey }) => selectedKey.color};
`;
