import React from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
} from "../styles";
import DrawerModal from "../../pages/DrawerModal/DrawerModel";
import { useState } from "react";

export default function CaseManager({
  caseManager,
  title = "IIAC Counsel",
  disabled = false,
  adhocPending = false,
  mediator,
  titleTag,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>
          {mediator?.presidingArbitrator === "yes"
            ? "Presiding Arbitrator"
            : titleTag
            ? titleTag
            : title}
        </CaseManagerTitle>
        <Flex className="flex-1">
          {caseManager?.name ? (
            <>
              <CaseManagerProfile
                src={
                  caseManager?.avatarUrl
                    ? caseManager?.avatarUrl
                    : require("../../../assets/images/userPlaceholder.svg")
                }
              />
              <CaseManagerName onClick={(e) => setModal(true)}>
                {caseManager?.name}
              </CaseManagerName>
            </>
          ) : null}
        </Flex>
        {adhocPending && (
          <div className="pending_mediator">
            (Ad-hoc) {title} is yet to sign up.
          </div>
        )}
      </Column>
      <DrawerModal
        {...{ modal, setModal }}
        id={caseManager?.id}
        type={title}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
