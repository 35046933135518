import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles, Button } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddDocBundleDrawer from "./AddDocumentBundle";
import useNotification from "../../../hooks/useNotification";
import { Tooltip } from "@material-ui/core";
import useLoader from "../../../hooks/useLoader";
import DeleteIcons from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EditBundleName from "./EditBundleName";
import AlertDialog from "../../common/Alert";
import useBundle from "../../../hooks/useBundle";
import {
  PostAddRounded,
  EventNote,
  LibraryAddRounded,
  RemoveCircleOutline,
  Description,
  LibraryBooks,
} from "@material-ui/icons";
import HearingBundleShared from "../HearingBundleShared";
import Annotation from "./Annotation";
import HttpsIcon from "@material-ui/icons/Https";
import PublicIcon from "@material-ui/icons/Public";
import HttpsLockOpen from "@material-ui/icons/LockOpen";
import BundleService from "../../../services/BundleService";
import BundleAddPages from "../../common/CustomBundle/index";
import BundleDeletePages from "../../common/CustomBundle/DeletePage";
import AddPages from "../../common/CustomBundle/AddPages";
import PdfViewer from "./PdfViewer";

const Index = ({
  id,
  setRefreshDetails,
  refreshDetails,
  success,
  setSuccess,
  documents,
  viewOnly,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bundleName, setBundleName] = useState();
  const caseId = id;
  const { triggerNotify, setTriggerNotify } = useNotification();
  const { bundleOpen } = useBundle();

  useEffect(() => {
    if (triggerNotify) {
      setRefreshDetails(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  useEffect(() => {
    async function getBundleName() {
      try {
        const response_data = await BundleService.getBundleName(caseId);
        if (response_data) {
          if (bundleOpen?.boolean === true && success) {
            const updatedArray = [
              ...response_data?.bundleDetails.slice(0, -1),
              {
                ...response_data?.bundleDetails[
                  response_data?.bundleDetails.length - 1
                ],
                newKey: bundleOpen?.boolean,
              },
            ];
            setBundleName(updatedArray);
          } else {
            setBundleName(response_data?.bundleDetails);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setSuccess(false);
      }
    }
    if (refreshDetails) {
      setRefreshDetails(false);
      getBundleName();
    } else if (bundleOpen) {
      getBundleName(bundleOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails, bundleOpen]);

  return (
    <Table
      {...{
        caseId,
        bundleName,
        setRefreshDetails,
        refreshDetails,
        documents,
        viewOnly,
      }}
      boolean={bundleOpen?.boolean}
    />
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({
  caseId,
  bundleName,
  setRefreshDetails,
  refreshDetails,
  boolean,
  documents,
  viewOnly,
}) => {
  return (
    <div>
      {bundleName
        ? bundleName.map((item, index) => (
            <TableWithAccordion
              key={index}
              title={item?.bundleName}
              id={item?.id}
              caseId={caseId}
              bundleDocs={item?.bundleDocuments}
              setRefreshDetails={setRefreshDetails}
              refreshDetails={refreshDetails}
              bundleCode={item?.bundleCode}
              visibilityType={item?.visibilityType}
              self={item?.self}
              newKey={item?.newKey}
              boolean={boolean}
              documents={documents}
              pageNoFlag={item?.pageNoFlag}
              tableofContentsFlag={item?.tableofContentsFlag}
              {...{ viewOnly }}
            />
          ))
        : ""}
    </div>
  );
};

function TableWithAccordion({
  id,
  title,
  bundleDocs,
  caseId,
  refreshDetails,
  setRefreshDetails,
  bundleCode,
  self,
  boolean,
  newKey,
  documents,
  visibilityType,
  pageNoFlag,
  tableofContentsFlag,
  viewOnly,
}) {
  const classes = useStyles();
  const [openBundleModal, setOpenBundleModal] = useState(false);
  const [docName, setDocName] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const { setLoader } = useLoader();
  const [editBundle, setEditBundle] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [modal, setModal] = useState(false);
  const [annotation, setAnnotation] = useState(false);
  const [addpage, setAddpage] = useState(false);
  const [delpage, setDelpage] = useState(false);
  const [add, setAdd] = useState(false);

  useEffect(() => {
    async function getBundleName() {
      try {
        const response_data = await BundleService.getDocumentList(caseId, id);
        if (response_data) {
          setDocName(response_data?.documentList);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    getBundleName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newKey && documents && docName?.length) {
      setTimeout(() => {
        setOpenBundleModal(boolean);
      }, 1000);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newKey, documents, docName?.length]);

  const customBundleDelete = async (id) => {
    try {
      setLoader({ state: true, message: "Delete Bundle..." });
      const res = await BundleService.customBundleDelete(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
      setRefreshDetails(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const openConfirmDelele = (id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Cancel",
      secondaryBtnText: "OK",
      clickSecondarybtn: () => customBundleDelete(id),
      clickPrimaryBtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure? you want to delete this bundle?`,
      heading: "Delete Bundle",
      descriptionTextStyle: {
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const actions = [
    {
      text: "Add Page Number & TOC",
      props: {
        onClick: () => addPageNumberBundle(id),
      },
      disabled:
        !bundleDocs || pageNoFlag || tableofContentsFlag || !self
          ? true
          : false,
      icon: (
        <PostAddRounded
          style={{
            fill:
              !bundleDocs || pageNoFlag || tableofContentsFlag || !self
                ? "#8294a5"
                : "#00838c",
          }}
        />
      ),
      tooltip: "Add Page Number & TOC",
    },
    {
      text: "Regenerate Document",
      props: {
        onClick: () => setOpenBundleModal(true),
      },
      disabled: docName?.length && self ? false : true,
      icon: (
        <Description
          style={{ fill: docName?.length && self ? "#00838c" : "#8294a5" }}
        />
      ),
      tooltip: "Regenerate Document",
    },
    {
      text: "Edit Bundle Name",
      props: {
        onClick: () => setEditBundle(true),
      },
      disabled: self ? false : true,
      icon: <EditIcon style={{ fill: self ? COLORS.COLOR_DARK : "#8294a5" }} />,
      tooltip: "Edit Bundle Name",
    },
    {
      text: "Annotation",
      props: {
        onClick: () => setAnnotation(true),
      },
      disabled: bundleDocs && self ? false : true,
      icon: (
        <EventNote
          style={{ fill: self && bundleDocs ? COLORS.COLOR_DARK : "#8294a5" }}
        />
      ),
      tooltip: "Annotation",
    },
    {
      text: "Add Section",
      props: {
        onClick: () => setAddpage(true),
      },
      disabled:
        !bundleDocs || pageNoFlag || tableofContentsFlag || !self
          ? true
          : false,
      icon: (
        <LibraryAddRounded
          style={{
            fill:
              !bundleDocs || pageNoFlag || tableofContentsFlag || !self
                ? "#8294a5"
                : COLORS.COLOR_DARK,
          }}
        />
      ),
      tooltip: "Add section",
    },
    {
      text: "Delete pages",
      props: {
        onClick: () => setDelpage(true),
      },
      disabled:
        !bundleDocs || pageNoFlag || tableofContentsFlag || !self
          ? true
          : false,
      icon: (
        <RemoveCircleOutline
          style={{
            fill:
              !bundleDocs || pageNoFlag || tableofContentsFlag || !self
                ? "#8294a5"
                : COLORS.LOGOUT_RED,
          }}
        />
      ),
      tooltip: "Delete pages",
    },
    {
      text: "Delete",
      props: {
        onClick: () => openConfirmDelele(id),
      },
      disabled: self ? false : true,
      open: false,
      icon: (
        <DeleteIcons style={{ fill: self ? COLORS.LOGOUT_RED : "#8294a5" }} />
      ),
    },
    {
      text: "Add page",
      props: {
        onClick: () => setAdd(true),
      },
      disabled:
        !bundleDocs || pageNoFlag || tableofContentsFlag || !self
          ? true
          : false,
      icon: (
        <LibraryBooks
          style={{
            fill:
              !bundleDocs || pageNoFlag || tableofContentsFlag || !self
                ? "#8294a5"
                : "#00838c",
          }}
        />
      ),
      tooltip: "Add page",
    },
    {
      text: "View",
      props: {
        onClick: () => setModal(true),
      },
      disabled: self ? false : true,
      icon:
        visibilityType === "all" ? (
          <PublicIcon style={{ fill: self ? "#00838c" : "#8294a5" }} />
        ) : visibilityType === "others" ? (
          <HttpsLockOpen style={{ fill: self ? "#00838c" : "#8294a5" }} />
        ) : (
          <HttpsIcon style={{ fill: self ? "#00838c" : "#8294a5" }} />
        ),
      tooltip:
        visibilityType === "all"
          ? "Public"
          : visibilityType === "others"
          ? "Share With Counsel and Neutral"
          : "Private",
    },
  ];

  const addPageNumberBundle = async (id) => {
    try {
      setLoader({ state: true, message: "Add page number..." });
      const res = await BundleService.addPageNumberBundle(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
      setRefreshDetails(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <>
      <TableWrapper>
        <Accordion className={classes.root} expanded={expanded}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setExpanded((e) => !e)}
                expanded={expanded}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordionHeading onClick={() => setExpanded((e) => !e)}>
              {bundleCode ? `${bundleCode} - ${title}` : title}
            </AccordionHeading>
            {bundleDocs ? (
              <Actions>
                <PdfViewer
                  url={bundleDocs[0].url.url}
                  bundleCode={`${bundleCode} - ${title}`}
                />
              </Actions>
            ) : null}
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                paddingLeft: "25px",
                paddingBottom: "10px",
                paddingTop: "auto",
                overflow: "auto",
              }}
            >
              {bundleDocs?.length
                ? bundleDocs?.map((data, index) => (
                    <div key={index}>
                      <Tooltip
                        title={data?.url?.url.split("?")[0].split("/").pop()}
                        placement="top"
                      >
                        <H2
                          style={{
                            color: COLORS.BTN_GREEN,
                          }}
                          onClick={() => window.open(data?.url?.url)}
                        >
                          {data?.url?.url?.split("/").length > 0
                            ? data?.url?.url.split("?")[0].split("/").pop()
                            : "-"}{" "}
                        </H2>
                      </Tooltip>
                    </div>
                  ))
                : ""}
            </div>
          </AccordionDetails>
          {viewOnly !== true && (
            <ButtonContainer>
              <ActionButtonContainer>
                {actions.map((action, index) => (
                  <MuiButton
                    {...action.props}
                    actions={actions}
                    disabled={action.disabled}
                    hidden={action.hidden}
                    outlined={action.outlined}
                  >
                    <Tooltip
                      title={action.tooltip}
                      placement="top"
                      open={action.open}
                    >
                      {action.icon}
                    </Tooltip>
                  </MuiButton>
                ))}
              </ActionButtonContainer>
            </ButtonContainer>
          )}
        </Accordion>
      </TableWrapper>
      <AddDocBundleDrawer
        id={id}
        caseId={caseId}
        bundleName={title}
        docName={docName}
        bundleDocs={bundleDocs}
        {...{
          openBundleModal,
          setOpenBundleModal,
          setRefreshDetails,
          refreshDetails,
          setDocName,
        }}
        heading="Enter Bundle Name"
        btnText="Enter Bundle Name"
      />
      <HearingBundleShared
        {...{
          id,
          caseId,
          modal,
          setModal,
          visibilityType,
          setRefreshDetails,
        }}
      />
      <EditBundleName
        {...{ id, caseId, editBundle, setEditBundle, title, setRefreshDetails }}
      />
      <Annotation {...{ id, caseId, annotation, setAnnotation }} />
      <BundleAddPages
        {...{
          id,
          addpage,
          setAddpage,
          caseId,
          docName,
          bundleDocs,
          title,
          setRefreshDetails,
        }}
      />
      <BundleDeletePages {...{ id, delpage, setDelpage, setRefreshDetails }} />
      <AddPages
        {...{
          id,
          add,
          setAdd,
          setRefreshDetails,
          caseId,
          docName,
          bundleDocs,
          title,
        }}
      />
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "center",
          fontFamily: theme.fonts.primaryFontSemiBold,
        }}
      />
    </>
  );
}

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
`;
export const AccordionHeading = styled.div`
  width: 230px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
  display: flex;
  align-items: center;
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  @media (min-width: 780px) {
    //width: 200px;
    width: auto;
    min-width: 100px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const TableWrapper = styled.div`
  // border: 2px solid black;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
  &:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0 21px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 6px 0 7px 0;
    padding: 0 21px 0 21px;
    &:nth-child(2) {
      margin-top: 9px;
    }
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteActions = styled.div`
  display: flex;
  margin-left: 18px;
  align-items: center;
  cursor: pointer;
  & div {
    margin-left: 35px;
  }
`;

export const FileUpdateIcon = styled.img`
  height: 24px;
  object-fit: contain;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0px 53px 0 25px;
  @media ${theme.breakpoints.sm_up} {
    display: flex;
  }
`;

// const ActionIcon = styled.button`
//   margin-left: 1.5em;
//   background-color: ${COLORS.PRIMARY_WHITE};
//   border: ${({ disabled }) =>
//     disabled
//       ? `1px solid ${COLORS.TRANSCRIPT_GRAY}`
//       : `1px solid ${COLORS.BTN_GREEN}`};
//   width: 2em;
//   height: 2em;
//   border-radius: 5px;
//   font-size: 1em;
//   text-align: center;
//   outline: none;

//   &:hover {
//     cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
//   }
// `;

const MuiButton = styled(Button)(({ disabled }) => ({
  marginLeft: "1.0em",
  width: "0.5em",
  height: "2em",
  border: disabled ? `1px solid #8294a5` : `1px solid ${COLORS.BTN_GREEN}`,
  backgroundColor: COLORS.PRIMARY_WHITE,
  minWidth: 32,
}));

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
