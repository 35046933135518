import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { CustomCheckbox } from "../../common/FormInputs";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";

const Index = ({
  setRefreshDetails,
  statusModal,
  setStatusModal,
  id,
  currentState,
  viewOnly,
}) => {
  const [status, setStatus] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (statusModal) {
      setStatus(currentState?.key);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusModal]);

  const actions = [
    {
      label: "Arbitration Reference",
      value: "arbitrationReference",
    },
    {
      label: "Notice To Arbitrate",
      value: "noticeToArbitrate",
    },
    {
      label: "Appointment of Arbitrator",
      value: "appointmentOfArbitrator",
    },
    {
      label: "Acceptance by Arbitrator",
      value: "acceptanceByArbitrator",
    },
    {
      label: "1st Notice (First Hearing Intimation)",
      value: "firstHearingIntimation",
    },
    {
      label: "Filing of Statement of Claim",
      value: "filingStatementofClaim",
    },
    {
      label: "Filing of Section 17",
      value: "filingofSection17",
    },
    {
      label: "Section 17 Order Passed",
      value: "section17OrderPassed",
    },
    {
      label: "Filing of Statement of Defence",
      value: "filingofStatementofDefence",
    },
    {
      label: "Rejoinder from Claimant",
      value: "rejoinderfromClaimant",
    },
    {
      label: "Surrejoinder from Respondent",
      value: "surrejoinderFromRespondent",
    },
    {
      label: "2nd Notice (MOM)",
      value: "2ndNoticeMOM",
    },
    {
      label: "Cross Examination - Claimant Witness",
      value: "crossExaminationClaimantWitness",
    },
    {
      label: "Cross Examination - Respondent Witness",
      value: "crossExaminationRespondentWitness",
    },
    {
      label: "Arguments",
      value: "arguments",
    },
    {
      label: "Reserved for Award",
      value: "reservedForAward",
    },
  ];

  const onFormSubmit = async (id, status) => {
    try {
      setLoader({ state: true, message: "update case status..." });
      const payload = {
        status: status,
      };
      const response = await CaseService.updateCaseStatus(id, payload);
      if (response.message) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
      setRefreshDetails(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setStatusModal(false);
      setStatus("");
    }
  };

  return (
    <Modal
      open={statusModal}
      onClose={() => setStatusModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer>
        <HeadingContainer>
          <Heading>{"Case Lifecycle"}</Heading>
          <CloseModal
            onClick={() => setStatusModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <FormContainer>
          <div className="status-list">
            {actions?.map((item, index) => (
              <CheckboxContainer className="status_row">
                <CustomCheckbox
                  key={item?.id}
                  checked={item.value === status}
                  onChange={() =>
                    setStatus((prev) =>
                      item.value === prev ? null : item.value,
                    )
                  }
                />
                <div className="status_name">{item?.label}</div>
              </CheckboxContainer>
            ))}
          </div>
          <ButtonContainer>
            <PrimaryOutlinedCTAButton
              style={{ width: "45%" }}
              onClick={() => setStatusModal(false)}
            >
              {"Cancel"}
            </PrimaryOutlinedCTAButton>
            {viewOnly !== true && (
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={!status}
                onClick={() => onFormSubmit(id, status)}
              >
                {"Submit"}
              </PrimaryCTAButton>
            )}
          </ButtonContainer>
        </FormContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default Index;

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .status_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .status_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .status-list {
    overflow: auto;
    height: 265px;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 45px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
