/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { states } from "./constants";
import moment from "moment";

// const URLREGEX =
//   /(https?:\/\/(?:www\.|(?!www)[\w-]+\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\S*|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\S*|https?:\/\/(?:www\.|(?!www)[a-zA-Z0-9]+\.)[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const URLREGEX =
  /\b((https?|ftp):\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(:[0-9]{1,5})?(\/\S*)?\b/;

function validatePinCodeByState(state, pin) {
  if (state && pin) {
    const found = states.find((st) => st.value === state);
    const pinStart = pin?.toString().substring(0, 2);
    if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const SampleSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Old Password is required"),
  newPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .min(6, "Password must be minimum 6 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const OfflinePaymentSchema = Yup.object({
  paymentType: Yup.string().trim().required("Payment type is required"),
  details: Yup.string().optional(),
});

export const EditProfileSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  houseNumber: Yup.string().trim().required("Address is required"),
  locality: Yup.string().trim().required("Locality is required"),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "PIN is required", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  landmark: Yup.string().trim().required("Landmark is required"),
});

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string().email("Please enter valid email").required(),
  password: Yup.string()
    .min(6, "Please enter 8 characters")
    .required("Password is required"),
});

const MOBILE_REGEX =
  /^(\+\d{1,2})?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(x\d+)?$/;

// const PASSWORD_REGEX =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

const validatePassword = (password) => {
  if (
    password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[0-9]/.test(password) ||
    !/[@#$%^&+*!=]/.test(password)
  ) {
    return false;
  }
  return true;
};

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const FAQValidationSchema = Yup.object({
  sections: Yup.array(
    Yup.object({
      question: Yup.string().trim().required("Question is mandatory"),
      answer: Yup.string().trim().required("Answer is mandatory"),
    })
  ),
});
export const TutorialValidationSchema = Yup.object({
  tutorialUrl: Yup.string().trim().required("Tutorial Video is mandatory"),
});

export const UploadAgreementValidationSchema = Yup.object({
  agreement_url: Yup.string()
    .trim()
    .required("Agreement Document is mandatory"),
});

export const AddZoomLinkValidation = Yup.object({
  zoomLink: Yup.string()
    .trim()
    .required("Video call link is mandatory")
    .matches(URLREGEX, "please enter the valid video call link"),
  startDate: Yup.string().nullable().trim().required("Date is mandatory"),
  startTime: Yup.string()
    .nullable()
    .trim()
    .required("Time is mandatory")
    .test(
      "MIN_TIME",
      "Start time must be greater then current time",
      function (value) {
        const date = moment(new Date(this.parent.startDate)).format(
          "YYYY-MM-DD"
        );
        const time = moment(new Date(value)).format("HH:mm:ss");
        const selectedTime = moment(`${date} ${time}`);
        return moment(new Date(selectedTime)).isAfter(moment(new Date()));
      }
    ),
});

export const UploadSummaryValidationSchema = Yup.object({
  summary: Yup.string().trim().required("Agreement Summary is mandatory"),
});

export const refundScheme = Yup.object({
  payments: Yup.array(
    Yup.object({
      amount: Yup.string()
        .trim()
        .test(
          "amount test",
          "Refund amount should be lesser than original amount",
          function (value) {
            if (this.parent?.checked && this.parent.type === "partial") {
              value = value?.toString()?.split(",")?.join("");
              if (
                Number(value) <= 0 ||
                Number(value) > Number(this.parent?.cartItem?.fee?.discPrice)
              ) {
                return false;
              }
              return true;
            }
            return true;
          }
        )
        .typeError("Refund amount cannot be empty/zero"),
    })
  ),
  comment: Yup.string().trim().required(),
});

export const AddHearingDateValidation = Yup.object({
  startDate: Yup.string().nullable(),
  startTime: Yup.string()
    .nullable()
    .trim()
    .test("startTime", "", function (value) {
      if (this?.parent?.startDate) {
        const matched = value?.startDate;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Time is required",
              path: "startTime",
            });
        }
      }
    }),
  // startTime: Yup.string()
  //   .nullable()
  //   .trim()
  //   .required("Time is mandatory")
  //   .test(
  //     "MIN_TIME",
  //     "Start time must be greater then current time",
  //     function (value) {
  //       const date = moment(new Date(this.parent.startDate)).format(
  //         "YYYY-MM-DD"
  //       );
  //       const time = moment(new Date(value)).format("HH:mm:ss");
  //       const selectedTime = moment(`${date} ${time}`);
  //       return moment(new Date(selectedTime)).isAfter(moment(new Date()));
  //     }
  //   ),
});

export const AudioSplit = Yup.object({
  audioFileName: Yup.string().trim().required("Audio File Name is required"),
  sessionNo: Yup.string().trim().required("Session no is required"),
});

export const CreateTranscriber = Yup.object({
  name: Yup.string("Please enter valid phone number")
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email id is Required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
  type: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  // password: Yup.string()
  //   .min(6, "Please enter 6 characters")
  //   .required("Password is required"),
});

export const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Current Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "newPassword",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const CreateGenericMeeting = Yup.object({
  name: Yup.string("Please enter valid phone number")
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email id is Required"),
});

export const AssigninBulkCaseId = Yup.object({
  startCaseId: Yup.string("Please enter number").required(
    "Please enter number"
  ),
  endCaseId: Yup.string("Please enter number").required("Please enter number"),
});

export const CreateNeutrals = Yup.object({
  name: Yup.string("Please enter valid phone number")
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email id is Required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
  type: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  // password: Yup.string()
  //   .min(6, "Please enter 6 characters")
  //   .required("Password is required"),
});

export const InvoiceValidationSchema = Yup.object({
  invoiceDescription: Yup.string().required("Invoice Description is required"),
  caseManagementLotRate: Yup.string()
    .trim()
    .required("Case Management Rate is required"),
  billingLot: Yup.string().trim().required("Billing Lot is required"),
  transcriptionLotRate: Yup.string()
    .trim()
    .required("Transcription Rate is required"),
  sessionStartTime: Yup.string()
    .nullable()
    .trim()
    .required("Session Start Time is required"),
  sessionEndTime: Yup.string()
    .nullable()
    .trim()
    .required("Session End Time is required"),
  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const ConfirmArbitratorValidationSchema = Yup.object({
  url: Yup.array().of(
    Yup.object().shape({
      uploadType: Yup.string()
        .trim()
        .test(
          "documenttype_required",
          "Document Type is required",
          function (value) {
            if (!value) {
              return false;
            }
            return true;
          }
        ),
      url: Yup.string()
        .trim()
        .test("document_required", "Document is required", function (value) {
          if (!value) {
            return false;
          }
          return true;
        }),
    })
  ),
});

export const ReopenCaseValidationSchema = Yup.object({
  counterClaimExtendTimeline: Yup.string().required(
    "Counter claim extend timeline is required"
  ),
});

export const GenerateInvoiceValidationSchema = Yup.object({
  feeType: Yup.string().required("Fees type is required"),
  amount: Yup.string()
    .trim()
    .test("amount test", "Amount cannot be empty/zero", function (value) {
      if (Number(value) <= 0) {
        return false;
      }
      return true;
    }),
  claimantPercentage: Yup.number()
    .min(0, "The number must be at least 0")
    .max(100, "The number must be at most 100")
    .required("Claimant percentage is required"),
});
