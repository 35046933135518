import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import {
  Label,
  LabelValueContainer,
  Value,
  ProfilePic,
  ProfilePicContainer,
} from "./styles";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import SystemService from "../../../services/SystemService";

export default function DrawerModal({
  modal,
  setModal,
  id,
  type = "IIAC Counsel",
  details = {},
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    async function getUserUserInfo() {
      try {
        setLoader({ state: true, message: "Getting user information" });
        const res = await SystemService.getProfile(id);
        if (res) {
          setUserDetails(res);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && modal && (type === "Mediator" || type === "Arbitrator")) {
      getUserUserInfo();
    } else {
      setUserDetails(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modal]);

  return (
    <Drawer
      anchor={"right"}
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{type}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <>
            <ProfilePicContainer>
              <ImageWrapper>
                <ProfilePic
                  src={
                    userDetails?.avatarUrl
                      ? userDetails?.avatarUrl
                      : require("../../../../src/assets/images/userPlaceholder.svg")
                  }
                ></ProfilePic>
              </ImageWrapper>
            </ProfilePicContainer>
            {type === "Mediator" || type === "Arbitrator" ? (
              <>
                <BasicDetails>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["user.name"]}</Label>
                      <Value>{userDetails?.name}</Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["user.email"]}</Label>
                      <Value>{userDetails?.email}</Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["user.mobile"]}</Label>
                      <Value>{userDetails?.mobile}</Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.qualification"]}</Label>
                      <Value>
                        {userDetails?.qualification
                          ? userDetails?.qualification
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.yearsOfExperience"]}</Label>
                      <Value>
                        {userDetails?.experience
                          ? userDetails?.experience
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.language"]}</Label>
                      <Value>
                        {userDetails?.languagesKnown
                          ? userDetails?.languagesKnown
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.affiliatedOrg"]}</Label>
                      <Value>
                        {userDetails?.affiliatedOrg
                          ? userDetails?.affiliatedOrg
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.location"]}</Label>
                      <Value>
                        {userDetails?.location ? userDetails?.location : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.specialities"]}</Label>
                      <Value>
                        {userDetails?.specialities
                          ? userDetails?.specialities
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <LabelContainer>
                    <LabelValueContainer style={{ width: "auto" }}>
                      <Label>{labels["mediator.tagLine"]}</Label>
                      <Value>
                        {userDetails?.tagLine ? userDetails?.tagLine : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  {userDetails?.alternateMobileNumber?.length ? (
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "auto" }}>
                        <Label>
                          {labels["mediator.alternateMobileNumber"]}
                        </Label>
                        <Value>{userDetails?.alternateMobileNumber}</Value>
                      </LabelValueContainer>
                    </LabelContainer>
                  ) : null}
                  {userDetails?.institutionOfMediationTraining?.length ? (
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "auto" }}>
                        <Label>
                          {labels["mediator.institutionOfMediationTraining"]}
                        </Label>
                        <Value>
                          {userDetails?.institutionOfMediationTraining}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                  ) : null}
                  {userDetails?.numberOfMediationsConducted?.length ? (
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "auto" }}>
                        <Label>
                          {labels["mediator.numberOfMediationsConducted"]}
                        </Label>
                        <Value>
                          {userDetails?.numberOfMediationsConducted}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                  ) : null}
                </BasicDetails>
                <LabelContainer>
                  <LabelValueContainer style={{ width: "auto" }}>
                    <Label>{labels["mediator.shortbio"]}</Label>
                    <Value>
                      {userDetails?.shortBio ? userDetails?.shortBio : "-"}
                    </Value>
                  </LabelValueContainer>
                </LabelContainer>
              </>
            ) : (
              <BasicDetails>
                <LabelContainer>
                  <LabelValueContainer style={{ width: "auto" }}>
                    <Label>{labels["user.name"]}</Label>
                    <Value>{userDetails?.name}</Value>
                  </LabelValueContainer>
                </LabelContainer>

                <LabelContainer>
                  <LabelValueContainer style={{ width: "auto" }}>
                    <Label>{labels["user.email"]}</Label>
                    <Value>{userDetails?.email}</Value>
                  </LabelValueContainer>
                </LabelContainer>

                <LabelContainer>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 20 }}
                  >
                    <Label>{labels["user.mobile"]}</Label>
                    <Value>{userDetails?.mobile}</Value>
                  </LabelValueContainer>
                </LabelContainer>
              </BasicDetails>
            )}
          </>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  @media (min-width: 640px) {
    width: 618px;
  }
`;

const LabelContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
  @media (min-width: 640px) {
    margin-right: 0;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  margin-bottom: 2px;
  user-select: none;
`;

const BasicDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;
