import React, { Fragment, useRef, useState, useEffect } from "react";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import TranscriberService from "../../../services/TranscriberService";
import styled from "styled-components";
import usePrevious from "../../../hooks/usePrevious";

const initFilters = [
  {
    label: "Meeting Audio Transcripts",
    key: "meetingAudioTranscriptsCount",
    value: 0,
  },
  {
    label: "Live",
    key: "liveTranscriptCount",
    value: 0,
  },
  {
    label: "Recording Transcripts",
    key: "recordingTranscriptsCount",
    value: 0,
  },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Meeting Audio Transcripts":
      value = "meetingAudioTranscriptsCount";
      break;
    case "Live":
      value = "live";
      break;
    case "Recording Transcripts":
      value = "recordingTranscripts";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCasesTranscribers(id, query = "") {
  try {
    const response = await TranscriberService.getCMTranscriberCases(id, query);
    const stats = await TranscriberService.caseStatsTranscribers(id);
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

const TranscriberTable = ({ id }) => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const placeholderText = "Search cases";
  const [filters, setFilters] = useState(initFilters);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const filterPreviousValue = usePrevious(filters);

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (selectedFilter && filterPreviousValue) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const columns = [
    {
      field: "id",
      title: labels["id_type"],
      sorting: true,
      tooltip: "Unique Identifier for the Transcriber across IIAC platform",
      render: (rowData) => (
        <Bold style={{ paddingLeft: "29px" }}>{rowData?.caseId}</Bold>
      ),
      headerStyle: {
        paddingLeft: "40px",
      },
    },
    {
      field: "title",
      title: labels["case_title"],
      sorting: false,
      render: (rowData) => <Bold>{rowData?.title}</Bold>,
    },
    {
      field: "meetingId",
      title: "MeetingId",
      sorting: false,
      render: (rowData) => (
        <Bold style={{ paddingLeft: "14px" }}>{rowData?.meetingId}</Bold>
      ),
    },
    {
      field: "resolutionKind",
      title: "Resolution Kind",
      sorting: false,
      render: (rowData) => (
        <Bold style={{ paddingLeft: "14px" }}>{rowData?.resolutionKind}</Bold>
      ),
    },
    {
      field: "count",
      title: "Count",
      sorting: false,
      render: (rowData) => (
        <Bold style={{ paddingLeft: "14px" }}>{rowData?.count}</Bold>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query?.search) {
        params.search = query?.search;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCasesTranscribers(id, stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle="Cases"
          singularTitle=""
          {...{
            columns,
            data,
            MTRef,
            placeholderText,
            filters,
            selectedFilter,
            setSelectedFilter,
          }}
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default TranscriberTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
